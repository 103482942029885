import React, { useState, useEffect, useRef }  from 'react';
import { AskConfirm, ConfirmPopup } from 'components/ConfirmPopup.jsx';
import { GetRequest, PostRequest } from 'components/common';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';

const SettingsUserUnitPriceAttributeList = ( { userUnitPriceKey, tabConfig } ) => {
  const [inProgress, setInProgress] = useState(true)
  const confirmPopupRef = useRef({});
  const [attributes, setAttributes] = useState([])
  const [mode, setMode] = useState('listing');
  const [config, setConfig] = useState({});
  const [editingId, setEditingId] = useState('');
  const [newAttributeFormError, setNewAttributeFormError] = useState('');
  const [newAttributeValue, setNewAttributeValue] = useState('');

  const fetchIAttributes = () => {
    // https://diecuttemplates.com.dev/quote-builder/user_unit_price_attributes/list/corrugated_cardboard_layer
    if(mode != 'listing') { return }
    setInProgress( () => true );
    GetRequest({
      url: `/quote-builder/user_unit_price_attributes/list/${tabConfig.key}`,
      callback: (data) => {
        setAttributes( () => data);
        setInProgress( () => false );
      }
    })
  }

  const swithToEditMode = (e, id) => {
    if(e != undefined) { e.preventDefault(); }
    setEditingId( () => id )
    setMode( () => 'editing');
  }

  const swithToListingMode = (e) => {
    if(e != undefined) { e.preventDefault(); }
    setMode(() => 'listing');
    setEditingId( () => '' )
  }

  const deleteConfirm = (e, id) => {
    if(e != undefined) { e.preventDefault(); }
    AskConfirm(
      confirmPopupRef,
      t('quote_builder.delete_confirm'),
       performDelete,
      id
    );
  }

  const performDelete = (id) => {
    setInProgress( () => true );
    PostRequest({
      url: `/quote-builder/user_unit_price_attributes/delete/${tabConfig.key}/${id}`,
      callback: fetchIAttributes,
    })
  }

  const attributeRow = (attribute) => {
    return <tr key={"" + attribute.id + mode }>
      <td> {attribute.name} </td>
      <td>
        <div className="float-right">
          {attribute.can_be_edited && <a href="#" onClick={(e) => { swithToEditMode(e, attribute.id) } }>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i> {t('quote_builder.edit')}
          </a>}

          {!attribute.can_be_edited && <a className="disabled">
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i> {t('quote_builder.edit')}
          </a>}
          </div>
        </td>
      <td>
        <div className="float-right">
          {attribute.can_be_deleted && <a href="#" onClick={(e) => { deleteConfirm(e, attribute.id) } }>
            <i className="fa fa-trash-o" aria-hidden="true"></i> {t('quote_builder.delete')}
          </a>}

          {!attribute.can_be_deleted && <a className="disabled">
            <i className="fa fa-trash-o" aria-hidden="true"></i> {t(`quote_builder.cannot_delete_reason_${attribute.cant_be_deleted_reason}`)}
          </a>}
          </div>
      </td>
    </tr>
  }


  const getConfig = () => {
    // https://diecuttemplates.com.dev/quote-builder/user_unit_price_attributes/config/corrugated_cardboard_material/corrugated_cardboard_layer
    setInProgress( () => true );
    GetRequest({
      url: `/quote-builder/user_unit_price_attributes/config/${userUnitPriceKey}/${tabConfig.key}`,
      callback: (data) => {
        setConfig( () => data );
        setInProgress( () => false );
      },
    })
  }

  const handleAttributeValueClick = () => {
    const postUrl = (parseInt(editingId) > 0) ? `/quote-builder/user_unit_price_attributes/update_name/${editingId}` : `/quote-builder/user_unit_price_attributes/create/${config.key}`
    setInProgress( () => true );
    PostRequest({
      url: postUrl,
      data: {
        name: newAttributeValue,
      },
      callback: (_data) => {
        setMode( () => 'listing' )
        setInProgress( () => false );
      }
    })
  }

  const newAttributeForm = () => {
    return (
      <>
      <br />
      <div className="form-inline newAttributeForm" style={{ marginTop: '10px', marginLeft: '10px' }}>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-addon">
              {config.create_input_label != undefined && t(config.create_input_label)}
              {config.create_input_label == undefined && t('quote_builder.new_attribute_with_key').replace('%{key}', t(`quote_builder.${config.key}`).toLowerCase())}
              </div>
              <input
                type="text"
                className={newAttributeFormError != '' ? 'input-error form-control' : 'form-control'}
                size="25"
                value={newAttributeValue}
                onChange={(e) => {setNewAttributeValue(e.target.value)}}
                />
            </div>
          </div>

          <button type="button" className="btn simple-btn" onClick={handleAttributeValueClick} > {t('quote_builder.save')} </button> &nbsp;
          <button className="btn" type="button" onClick={() => setMode( () => 'listing' ) }> {t('quote_builder.cancel')} </button>

          {newAttributeFormError != '' && <center> <small className="error"> {t(newAttributeFormError)} </small> </center> }
      </div>
      </>
    )
  }

  const newAttributeSubForm = () => {
    const afterSave = () => { setMode( () => 'listing' ) }
    return (<div>
      <QuoteBuilderForm config={config.create_sub_form} afterSave={afterSave} afterCancel={afterSave} />
    </div>)
  }

  const editAttributeForm = () => {
    return newAttributeForm();
  }

  const editAttributeSubForm = () => {
    const afterSave = () => {
      setMode( () => 'listing' )
    }

    return (<div>
      <QuoteBuilderForm config={config.create_sub_form} afterSave={afterSave} afterCancel={afterSave}
        showingState="edit" editStateId={editingId} editedKlass="UserUnitPriceAttribute" linkToSettings={false}
      />
    </div>)
  }

  useEffect(fetchIAttributes, [mode])
  useEffect(() => {
    if(mode == 'listing') {
      setNewAttributeValue( () => '')
    }

    if(mode == "editing" && config.create_sub_form == undefined) {
      setInProgress( () => true );
      GetRequest({
        url: `/quote-builder/user_unit_price_attribute/${editingId}`,
        callback: (data) => {
          setNewAttributeValue( () => data.name );
          setInProgress( () => false );
        },
      })
    }
  }, [mode])

  useEffect(getConfig, [])

  if(attributes.length == 0) { return t('quote_builder.please_wait') }

  return (<>
    {mode == "listing" && <table className={`table table-striped table-bordered-outside ${inProgress ? 'tableInProgress' : ''} `} style={{ marginTop: "20px"}}>
      <tbody>
        <tr>
          <th colSpan="3">
            <div className="float-left">
              { t(tabConfig.label) } ({attributes.length})
            </div>
          </th>
        </tr>

        {attributes.map(attributeRow)}

        <tr>
          <td colSpan="4">
            <div className="float-right">
              <button onClick={() => setMode('new') } className="btn simple-btn">
                <i className="fa fa-plus-square-o" aria-hidden="true"></i> &nbsp;
                  { tabConfig.new_title!= undefined && t(tabConfig.new_title) }
                  { tabConfig.new_title == undefined && t('nav.new') }
              </button>
            </div>
          </td>
        </tr>

      </tbody>
    </table>}

    {mode == "editing" && config.create_sub_form == undefined && editAttributeForm() }
    {mode == "editing" && config.create_sub_form != undefined && editAttributeSubForm() }
    {mode == "new" && config.create_sub_form == undefined && newAttributeForm() }
    {mode == "new" && config.create_sub_form != undefined && newAttributeSubForm() }

    <ConfirmPopup myRef={confirmPopupRef} />
  </>
  )
}

export default SettingsUserUnitPriceAttributeList;
