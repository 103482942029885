import React, { useState, useEffect, useRef }  from 'react';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';

const OptionsCommon = ( { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange } ) => {
  const [isVisible, setIsVisible] = useState(option.visible)
  const [defaultValue, setDefaultValue] = useState( formattedValue(option.default_value) )
  const [value, setValue] = useState( defaultValue )
  const [changed, setChanged] = useState(false)
  const [highlighted, setHighlighted] = useState(false)
  const onChange = (e) => {
    if(e.target.type == 'checkbox') {
      setValue( () => e.target.checked ? 1 : 0 )
    } else {
      setValue( () => e.target.value )
    }
    setChanged( () => true );
  }

  // const [messageQueue, setMessageQueue] = useState([])

  const [recommendedValue, setRecommendedValue] = useState(defaultValue)
  const [valueAutoCalculated, setValueAutoCalculated] = useState(option.value_formula)
  const showAbcHelperLine = () => { emitEvent(Constants.show_abc_line, { id: id, name: option.name }) }

  // {drawing_id: 37101, name: "p", value: 3, disabled: false, user_changed: false, visible: true}
  // todo what if disabled comes as true?
  // need to move this methods to other options, can we avoid copy/paste?

  const onDrawingOptionRecommendedValueChangedMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
      if(e.detail.value != undefined) {
        let newValue = formattedValue(e.detail.value)
        if(newValue == value) {
          //new value is same but we still want to signal that we are done.
          emitChangedEvent()
        }

        setRecommendedValue( () => newValue  )

        if(!e.detail.user_changed) {
          setDefaultValue( () => newValue )
          setValue( () => newValue )
          setValueAutoCalculated( () => true )
        }
      }

      if(e.detail.visible != undefined) {
        setIsVisible( () => e.detail.visible )
      }
    }
  }

  const emitChangedEvent = () => {
    if(unit == "infr") { return; }
    emitEvent('addDebugEntry', { event: "[OptionsCommon] SetValue event emitted", id: id, name: option.name, value: value, changed: changed} )
    emitEvent(Constants.drawing_option_change_completed, { id: id, name: option.name })
  }

  const onDrawingOptionVisibleChangedMsg = (e) => {
    if(e.detail.drawing_id == id && e.detail.name == option.name) {
      setIsVisible( () => e.detail.visible )
    }
  }

  const onDrawingOptionValueSetMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
      emitEvent('addDebugEntry', { event: "[OptionsCommon] setValue", name: option.name, value: formattedValue(e.detail.value), was: value, isVisible: isVisible })
      let newValue = formattedValue(e.detail.value)
      if(newValue == value) {
        //new value is same but we still want to signal that we are done.
        emitChangedEvent()
      }

      setValue( () => newValue )
      addToOptionsUserChanged(option)

      if(!isVisible) {
        // hack: we consider this to be "changed" in terms of redrawing but "not changed" so we don't trigger
        // redraw if redrawOnChange is set (e.g. booleans)
        setChanged( () => true );
      }

      if(e.detail.redraw && unit != "infr") {
        setRedrawRequested( () => true )
      }
    }
  }

  const onDrawingOptionRecommendationChangedAddonsMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
       setRecommendedValue( () => formattedValue(e.detail.value) );

       if (!optionsUserChanged.includes(option.name) ){
        let newValue = formattedValue(e.detail.value)
        setDefaultValue( () => newValue )
        setValue( () => newValue )
        setValueAutoCalculated( () => true )
      }
    }
  }

  const onDrawingOptionHighlightMsg = (e) => {
    if(e.detail.id == id && e.detail.name == option.name) {
      setHighlighted( (prev) => e.detail.value );
    }
  }

  const onDrawingOptionUnHighlightMsg = (e) => {
    if(e.detail.id == id) {
      setHighlighted( (prev) => false );
    }
  }

  const onOptionsUserChanged = () => {
    if (optionsUserChanged.includes(option.name) ){
      setValueAutoCalculated( () => false);
    }
  }

  const d_input_add_on_ref = useRef();
  const emitLabelWidth = () => {
    emitEvent(Constants.drawing_input_label_width, { id: id, name: option.name, width: $(d_input_add_on_ref.current).width() })
  }

  const adjustLabelWidthMsg = (e) => {
    if(e.detail.id == id && e.detail.name != option.name) {
      let myWidth = $(d_input_add_on_ref.current).width();
      let othersWidth = e.detail.width;
      if(othersWidth > myWidth) {
         $(d_input_add_on_ref.current).width(othersWidth);
      }
    }
  }

  // useEffect starts
  useEffect( () => {
    if(defaultValue != value) {
      addToOptionsUserChanged(option);
    }
  }, [value] )

  useEffect(emitChangedEvent, [value] )

  useEffect( onOptionsUserChanged, [optionsUserChanged] )

  if(redrawOnChange) {
    useEffect( () => {
      setRedrawRequested( () => changed )
    } , [value] )
  }

  useEffect( () => {
    setDefaultValue( () => formattedValue(option.default_value) )
    setValue( () => formattedValue(option.default_value) )
  } , [option.default_value])

  useEffect( () => setRecommendedValue( () => defaultValue )  , [defaultValue])
  // why did we need this? do we still need it?
  // useEffect( () => {
  //   let newValue = formattedValue(option.default_value)
  //   setDefaultValue( () => newValue )
  //   setValue( () => newValue )
  //   setRecommendedValue( () => newValue)
  // }, [ option.default_value ])

  useEffect( () => setValueAutoCalculated( () => option.value_formula), [option])
  useEffect(emitLabelWidth, [d_input_add_on_ref.current])

  // useEffect ends

  MyEventListener([
    {
      key: Constants.drawing_option_recommended_value_changed,
      callback: onDrawingOptionRecommendedValueChangedMsg
    },{
      key: Constants.drawing_option_visible_updated,
      callback: onDrawingOptionVisibleChangedMsg
    },{
      key: Constants.drawing_option_set_value,
      callback: onDrawingOptionValueSetMsg
    },{
      key: Constants.drawing_option_recommended_value_changed_add_ons,
      callback: onDrawingOptionRecommendationChangedAddonsMsg
    },{
      key: Constants.drawing_option_highlight,
      callback: onDrawingOptionHighlightMsg
    },{
      key: Constants.drawing_redraw_completed,
      callback: onDrawingOptionUnHighlightMsg
    },{
      key: Constants.drawing_addon_redraw_completed,
      callback: onDrawingOptionUnHighlightMsg
    },{
      key: Constants.drawing_input_label_width,
      callback: adjustLabelWidthMsg
    },
  ])

  return [isVisible, value, valueAutoCalculated, recommendedValue, onChange, showAbcHelperLine, changed, highlighted, d_input_add_on_ref]

}

export default OptionsCommon;
