import React, { useState, useEffect, useRef }  from 'react';
import Constants from 'components/show_drawing/Constants.jsx';
import { GetRequest } from 'components/common/index.js';
import { OptionLenghtWithUnit, OptionBoolean, OptionIntegerWithOptions, OptionIntegerWithRange, OptionPresets, InternalDimensionsChooser, SelectUnit, SelectQuantity, TagsAsOptions } from 'components/quote_builder_widget/form/index.js';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const DrawingOptions = ({ theme, id, setId, drawing }) => {
  const [unit, setUnit] = useState("mm")

  const unitForm = () => {
    return <SelectUnit
      id={id}
      drawing={drawing}
      unit={unit}
      theme={theme}
      setUnit={setUnit}
    />
  }

  const QuantityForm = () => {
    return <SelectQuantity
      id={id}
      drawing={drawing}
      unit={unit}
      theme={theme}
    />
  }

  const [options, setOptions] = useState([])
  const fetchOptions = () => {
    // https://diecuttemplates.com.dev/widget-drawing-options/0/en/72606/in
    GetRequest({
      url: `/widget-drawing-options/0/en/${id}/${unit}`,
      callback: (data) => {
        setOptions( () => data.values )
      }
    })
  }

  const calculateMaxHeight = () => {
    let h = $("#optionsCol").height();
    setMaxHeight( () => `${h}px` );
  }

  // const DispatchOptionsUpdated = () => { emitEvent(Constants.options_updated, id)  }

  const getOption = (option) => {
    if(option.input_type == 'length' && option.possible_values.length == 0) {
      return <OptionLenghtWithUnit
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        theme={theme}
      />
    }

    throw new Error("[DrawingOptions] getOption returning null for " + option.input_type)
  }

  const internalDimensions = () => {
    return '';

    if(!drawing.show_internal_dimensions) { return }
    return <InternalDimensionsChooser id={id} unit={unit} drawing={drawing} theme={theme}/>
  }

  const [onRecoverySetValueMsgReceived, setonRecoverySetValueMsgReceived] = useState(false);

  const onRecoverySetValueMsg = (msg) => {
  }

  const optionPresets = () => {
    return '';

    if(drawing.option_presets == undefined || drawing.option_presets.length == 0) { return }

    return <OptionPresets id={id} drawing={drawing} unit={unit} setRedrawRequested={setRedrawRequested} theme={theme} />
  }


  // useEffect starts
  useEffect(fetchOptions, [id, unit]);
  // useEffect starts

  return (
    <>
      { QuantityForm() }
      { unitForm() }
      { options.map(getOption) }
      <TagsAsOptions id={id} setId={setId} drawing={drawing} />
    </>
  )
}

export default DrawingOptions;
