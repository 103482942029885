import React, { useState, useEffect, useRef }  from 'react';
import { PostRequest, GetRequest } from 'components/common/index.js';

const FormNew = ( { identifier, setMode } ) => {
  const domainInputRef = useRef();
  const [domains, setDomains] = useState( [] );
  const [highlightColor, setHighlightColor] = useState(['#F44336']);

  const [addDomainFormVisible, setAddDomainFormVisible] = useState(false)
  const [addDomainFormHighlighed, setAddDomainFormHighlighed] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [newDomain, setNewDomain] = useState("")

  const fetchWidget = () => {
    if(identifier == undefined) { return }

    GetRequest({
      url: `/quote-builder/user-quote-builder-widgets/${identifier}` ,
      callback: (data) => {
        setDomains( () => data.domains );
        setHighlightColor( () => data.properties.highlight_color );
      }
    })
  }

  const addToDomains = () => {
    if(newDomain.split('.').length == 1) { return }

    setDomains( (prev) => prev.concat( [newDomain] ).filter(onlyUnique)  )
    setAddDomainFormHighlighed( () => false )
    setAddDomainFormVisible( () => false );
    setNewDomain( () => '' );
  }

  const removeFromDomains = (domain) => {
    setDomains( (prev) => prev.filter( (d) => d != domain ))
  }

  const domainWithHttp = (domain) => `https://${domain}`

  const domainsList = () => {
    return (<div>
      {domains.map( (domain) => {
        return <div>
          <span className="domain-name"> {domainWithHttp(domain)} </span>
          <a href="#" className="btn" onClick={(e) => { e.preventDefault(); removeFromDomains(domain)} } >
            <i className="fa fa-trash-o" aria-hidden="true"></i>
            {t('quote_builder_widget.delete')}
          </a>
        </div>
      } )  }
    </div>)
  }

  const addDomainForm = () => {
    return (
      <div className="form-inline">
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-addon"> https://
            </div>
            <input type="text" className="form-control" size="35" ref={domainInputRef} value={newDomain} placeholder="www.example.com" onChange={ (e) => setNewDomain( () => e.target.value ) }/>
          </div>
        </div>

        <button type="button" className="btn simple-btn" onClick={addToDomains}> {t('quote_builder_widget.save')} </button>
        <button className="btn" type="button" onClick={ () => setAddDomainFormVisible( () => false ) }> {t('quote_builder_widget.cancel')} </button>
    </div>)
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setMode( () => 'listing' )
  }

  const handleSave = (e) => {
    e.preventDefault();

    if(domains.length == 0) {
      setAddDomainFormVisible( () => true )
      setAddDomainFormHighlighed( () => true )
      $(domainInputRef.current).focus();
      return;
    }

    PostRequest({
      url: '/quote-builder/user-quote-builder-widgets',
      data: {
        identifier: identifier,
        domains: domains,
        highlight_color: highlightColor,
      },
      callback: ( data ) => {
        setMode( () => 'listing' )
      },
      400: (data) => {
        setErrorMessage( () => data.error )
      }
    })
  }

  // useEffect
  useEffect(fetchWidget, [identifier])
  // useEffect

  return (<div>
    <div className="col-lg-12">

        <table className="table table-striped table-bordered-outside quote_builder_table">
          <tbody>
            <tr>
              <th colSpan="2"> { identifier != '' ? t('quote_builder_widget.new_title') : t('quote_builder_widget.update_title') } </th>
            </tr>

            <tr>
              <td style={{ width: '560px' }}> {t('quote_builder_widget.domains')} <br /> <small> {t('quote_builder_widget.domains_info')} </small> </td>
              <td>
                <div style={ addDomainFormHighlighed ? { padding: "20px", border: "1px solid #ff0000" } : {}}>
                  {domainsList()}
                  <br />

                  <button className="btn simple-btn" onClick={ (e) => { e.preventDefault(); setAddDomainFormVisible( () => true ) } } >
                    <i className="fa fa-plus-square-o" aria-hidden="true"></i> {t('quote_builder_widget.add_domain')}
                  </button>
                  {addDomainFormVisible && <> <br /> <br /> {addDomainForm()} </>}
                </div>
              </td>
            </tr>

            <tr>
              <td> {t('quote_builder_widget.highlight_color')} <br /> <small>  {t('quote_builder_widget.highlight_color_info')} </small>  </td>
              <td> <input type="color" value={highlightColor} onChange={ (e) => setHighlightColor( () => e.target.value )  } /> </td>
            </tr>

            <tr>
              <td colspan="2">
                {errorMessage != '' && <div className="alert alert-danger fade in"> <strong>  <i className="fa fa-exclamation-circle"></i> {errorMessage} </strong> </div>}
                <button className="btn simple-btn" type="button" onClick={handleSave}> {t('quote_builder_widget.save')} </button> &nbsp; &nbsp;
                <button className="btn" onClick={handleCancel}> {t('quote_builder_widget.cancel')} </button>
              </td>
            </tr>

          </tbody>
        </table>


    </div>

    </div>
  )
}

export default FormNew;
