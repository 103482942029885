import React, { useState, useEffect }  from 'react';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import SettingsUserUnitPriceAttributeList from 'components/quote_builder/SettingsUserUnitPriceAttributeList.jsx';

const SettingsTabs = ( { config, breadcrumbs, showingState, afterSave, afterCancel, editStateId, hideDeleteFromList } ) => {
  const [unit, setUnit] = useState(window.user_unit || 'mm')

  const tabsConfig = config.tabs;
  if(tabsConfig == undefined) { return <QuoteBuilderForm
    config={config}
    showingState={showingState}
    afterSave={afterSave}
    afterCancel={afterCancel}
    editStateId={editStateId}
    hideDeleteFromList={hideDeleteFromList}
  />}

  const [tabIndex, setTabIndex] = useState(0)
  const _setTabIndex = (e, i) => {
    e.preventDefault();
    setTabIndex(() => i)
  }

  const getTab = (tab, i) => {
    return <li role="presentation" class={tabIndex == i ? 'active' : ''}> <a href="#" onClick={ (e) => _setTabIndex(e, i) }> { tabTitle( tabsConfig[i] ) } </a> </li>
  }


  const UserUnitPriceAttributesByUnitContent = () => {
    const tabConfig = tabsConfig[ tabIndex ];

    const unitSelector = () => {
      return <div className="float-left">
        <table className="table table-striped table-bordered-outside">
          <tbody>
            <tr>
              <th> &nbsp; { t("quote_builder.unit") }  &nbsp; </th>
              <td>
                <div className="float-right">
                  <select value={unit} onChange={ (e) => setUnit( () => e.target.value ) } className="form-control general-frm-element">
                  <option value="mm"> { t("drawing.unit_mm") } </option>
                  <option value="in"> { t("drawing.unit_in") } </option>
                </select>
                </div>
            </td>
            </tr>
          </tbody>
      </table>
      </div>
    }

    return <>
      {unitSelector()}
      {tabConfig.attributes.map( (attributeConfig) => {
        return unit == attributeConfig.unit && <SettingsUserUnitPriceAttributeList userUnitPriceKey={config.key} tabConfig={attributeConfig} />
      } ) }
    </>
  }

  const tabContent = () => {
    const tabConfig = tabsConfig[ tabIndex ];

    switch(tabConfig.form_type) {
    case "UserUnitPrices":
      return <QuoteBuilderForm
        config={config}
        showingState={showingState}
        afterSave={afterSave}
        afterCancel={afterCancel}
        editStateId={editStateId}
        hideDeleteFromList={hideDeleteFromList}
        />
      break;

    case "UserUnitPriceAttribute":
      return <SettingsUserUnitPriceAttributeList userUnitPriceKey={config.key} tabConfig={tabConfig} />
      break;

     case "UserUnitPriceAttributesByUnit":
      return UserUnitPriceAttributesByUnitContent()
      break;

    default:
      throw "[error] tabContent cannot be found for " + tabConfig.form_type;
    }

    return <>

    </>
  }

  const tabTitle = (tabConfig) => {
    switch(tabConfig.form_type) {
    case "UserUnitPrices":
      return t("quote_builder.my_unit_prices");
      break;

    case "UserUnitPriceAttribute":
      return t(tabConfig.label)
      break;

    case "UserUnitPriceAttributesByUnit":
      return t(tabConfig.label)
      break;

    default:
      throw "[error] tabTitle cannot be found for " + tabConfig.form_type;
    }
  }

  const breadcrumbHeader = () => {
    if(breadcrumbs == undefined) { return }
    return <><div className="page-in-name"> {breadcrumbs.split(",").map( (br) => t("quote_builder." + br)  ).join(" | ") } </div> <hr /></>
  }
  useEffect(() => {
    window.user_unit = unit;
    setCookie("unit", unit, 365)
  }, [unit])


  return (
    <div>
      {breadcrumbHeader()}
      <ul class="nav nav-pills">
        { config.tabs != undefined && config.tabs.map(getTab) }
      </ul>

      <div key={tabIndex} style={{marginTop: "10px"}}> {tabContent()} </div>
    </div>
  )
}

export default SettingsTabs;
