import React, { useState, useEffect, useContext, useRef } from 'react';
import { ContentPopup } from 'components/ContentPopup.jsx';
import Util from 'components/quote_builder/Util.jsx';

const LayoutSvg = ( { item, options, presentedOptions, navSection} ) => {
  const getLoading = () => {
    switch(theme) {
      case 'black_bg':
        return '<center> <br /> <br /> <br /> <img src="/double-ring-white.gif" width="30px"/> </center>'; break;
      case 'white_bg':
        return '<center> <br /> <br /> <br /> <img src="/bx_loader.gif" width="30px"/> </center>'; break;
      case 'gray_bg':
        return '<center> <br /> <br /> <br /> <img src="/bx_loader.gif" width="30px"/> </center>'; break;
    }
  }

  const [svg, setSvgString] = useState(getLoading());
  const [theme, setTheme] = useState(window.redraw_theme);
  const [outputYLength, setOutputYLength] = useState(null);
  const [outputXLength, setOutputXLength] = useState(null);
  const [showDimensions, setShowDimension] = useState(false);

  const redrawFinished = useRef(false);

  const [numX, setNumX] = useState(null);
  const [numY, setNumY] = useState(null);
  const [numTotal, setNumTotal] = useState(null);
  const [numOfdrawingsInSet, setNumOfdrawingsInSet] = useState(null);

  const [wastageCount, setWastageCount] = useState(null);
  const [wastageUnit, setWastageUnit] = useState(null);
  const [count, setCount] = useState(null);

  const [onlyForPlotter, setOnlyForPlotter] = useState(false);

  const onRedrawFinished = (data) => {
    setSvgString(_prev => data.svg);
    setOutputXLength(data.output_dimensions.output_x_length)
    setOutputYLength(data.output_dimensions.output_y_length)
    setNumX(data.num_x);
    setNumY(data.num_y);
    setNumTotal(data.num_total);
    setNumOfdrawingsInSet(data.num_of_drawings_in_set);
    setOnlyForPlotter(data.only_for_plotter);
    redrawFinished.current = true;
  }

  const idForSvg = () => {
    if(item.extra_item_reason != undefined) {
      return item.uuid + '_' + item.extra_item_reason + '_' + navSection.index;
    } else {
      return item.uuid + '_' + navSection.index;
    }
  }

  const svgHeight = 550;
  const redraw = () => {
    $(`#layout_svg_${idForSvg()}`).height(svgHeight);

    options.authenticity_token = $('meta[name=csrf-token]').attr('content');

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options)
    };
    updateRequestCount(1);
    fetch(item.redraw_url + '?svg_id=' + idForSvg(), requestOptions)
      .then(response => response.json())
      .then((data) => {
        updateRequestCount(-1);
        switch(data.result) {
          case 'OK':
            onRedrawFinished(data);
            break;
          case 'validation_error':
            onValidationError(data);
            break;
          default:
            console.log('not good');
          }
      }
    )
  }

  useEffect(() => {
    if($(`#svg_${idForSvg()}`).length == 0) { return; }
    $(`#layout_svg_${idForSvg()}`).height(svgHeight);
    $(`#svg_${idForSvg()}`).height(svgHeight);
    window.SVGControlsDisabled = true;
    setTimeout(() => { performPanZoomInstance(`#svg_${idForSvg()}`)}, 250);

  }, [svg]);

  useEffect(redraw, []);

  const getContentForPresentedOptions = () => {
    const copyOfPresentedoptions = { ...presentedOptions };
    const dielineOptions = copyOfPresentedoptions.dieline_options;
    delete copyOfPresentedoptions.dieline_options;

    const entryRow = (entry) => {
      return (
        <tr>
          <td>
            <strong> {entry[0]} </strong>
          </td>

          <td>
            <span> {entry[1]} </span>
          </td>
        </tr>
        );
    };

    return (
      <div>
        <table className="table table-striped">
          <tbody>
            <tr>
              <th colSpan="2"> <h3> {t('layouts.confirm_tab_dieline')} </h3> </th>
            </tr>
            {Object.entries(dielineOptions).map(entryRow)}
          </tbody>
        </table>

        <br />
        <br />

        <table className="table table-striped">
          <tbody>
            <tr>
              <th colSpan="2">  <h3>  {t('layouts.confirm_tab_nesting')} </h3>  </th>
            </tr>
            {Object.entries(copyOfPresentedoptions).map(entryRow)}
            </tbody>
        </table>
    </div>);
  }

  const [contentForPresentedOptions, setContentForPresentedOptions] = useState(getContentForPresentedOptions());
  const showDimensionsContentPopupRef = useRef();
  const showDimensionsContentPopup = () => {
    let callback = () => {
      showDimensionsContentPopupRef.current.style.display = "none";
      setShowDimension(false);
    };

    let response = <ContentPopup myRef={showDimensionsContentPopupRef} autoOpen={true} content={contentForPresentedOptions} cancelButton={false} beforeClose={callback} />;

    return response;
  };

  useEffect(() => {
    let listen = 'quote_builder.count_changed';
    let listener = function(e) {
      setCount( () => parseInt(e.detail) )
    }
    document.addEventListener(listen, listener);

    return () => { document.removeEventListener(listen, listener);}
  }, [])

  const onWastageCountChanged = (e) => {
    const sameNavSection = (e) => {
      if (e?.detail?.nav_section?.index === undefined) { return false; }
      if (navSection?.index === undefined) { return false; }

      return navSection.index == e.detail.nav_section.index;
    }

    if(!sameNavSection(e)) { return; }
    setWastageCount( () => e.detail.values.wastage_count)
    setWastageUnit( () => e.detail.values.wastage_unit)
  }

  useEffect(() => {
    let listen = 'quote_builder.nav_section_wastage_count_changed';
    document.addEventListener(listen, onWastageCountChanged);
    return () => { document.removeEventListener(listen, onWastageCountChanged);}
  }, [])

  useEffect(() => {
    let listen = 'quote_builder.nav_section_wastage_unit_changed';
    document.addEventListener(listen, onWastageCountChanged);
    return () => { document.removeEventListener(listen, onWastageCountChanged);}
  }, [])

  const getNumberOfSheetsStr = () => {
    if(numTotal == undefined || numTotal == null) { return ''; }
    if(count == undefined || count == null) { return ''; }
    if(wastageCount == undefined || wastageCount == null) { return getNumberOfSheets(); }
    if(wastageCount == 0) { return getNumberOfSheets(); }

    return `${getNumberOfSheets()} + ${numberOfSheetsWaste()} = ${getNumberOfSheets() + numberOfSheetsWaste()}`;
  }

  const numberOfSheetsWaste = () => {
    if(wastageCount == undefined || wastageCount == null) { return 0 }

    if(wastageUnit == 'sheet') { return parseInt(wastageCount); }
    return Math.ceil((parseFloat(countForWastage()) * numOfdrawingsInSet) / numTotal);
  }

  const getNumberOfSheets = () => {
    return Math.ceil((parseFloat(count) * numOfdrawingsInSet) / numTotal);
  }

  const countForWastage = () => {
    if(wastageUnit == 'product') { return wastageCount; }
    if(wastageUnit == 'sheet') { return wastageCount * numTotal;}
  }

  const getWasteCountStr = () => {
    let wastage_unit_ = t(`quote_builder.wastage_unit_${wastageUnit}`);

    if(wastageUnit == 'sheet') {
      return `${wastageCount} ${wastage_unit_}`
    }

    if(wastageUnit == 'product') {
      return `${wastageCount} ${wastage_unit_} = ${numberOfSheetsWaste()} ${t('quote_builder.wastage_unit_sheet')}`
    }

    return '';
  }

  const setShowDimensionTrue = () => {
    setContentForPresentedOptions(getContentForPresentedOptions);
    setShowDimension( () => true )
  }

  return (
      <div className="quote_builder_svg_container">
       {item.part_names.length> 0 && (
        <div className="left">
          <span className="info">
            <h5>
               {item.layout_part_group_name != undefined && item.layout_part_group_name != '' && <> {item.layout_part_group_name} | &nbsp; </> }
               {item.part_names.map(str => `${str}`).join(', ')}
            </h5>
          </span>
        </div>)}

        <div className={`layout_svg_${theme}`} id={`layout_svg_${idForSvg()}`}>
          <div className={'redraw_theme_' + theme } dangerouslySetInnerHTML={{__html: svg}}></div>
        </div>

        <br />
        <div className="row">
          <div className="col-lg-12 layout-output">
            <div className="float-right">
              <table className="quote-layout-info-table" style={{ 'marginRight': "-30px"}}>
                <tbody>
                  <tr key="main_args">
                    <td align="right">
                       {numberOfSheetsWaste() > 0 && ( <span className="info-quote"> {t('quote_builder.wastage_count')}: {getWasteCountStr()} </span>)}
                    </td>

                    <td align="right"> {count != undefined && <span className="info-quote">  {t('quote_builder.count')}: {count} </span> } </td>

                    <td align="right">
                      {getNumberOfSheetsStr() != '' && <span className="info-quote"> {t('quote_builder.number_of_sheets')}: {getNumberOfSheetsStr()} </span> }
                    </td>

                  </tr>

                  <tr key="output_xy">
                    <td align="left">
                      <span className="arrow">
                        <i className="fa fa-arrows-h" aria-hidden="true"></i>
                      </span>
                      <span className="info-quote"> {t('layouts.output_x_length')} {outputXLength}
                      </span>
                    </td>
                    <td align="left">
                      <span className="arrow">
                        <i className="fa fa-arrows-v" aria-hidden="true"></i>
                      </span>
                      <span className="info-quote">{t('layouts.output_y_length')} {outputYLength} </span>
                    </td>
                    <td align="right"> {numX && <span className="info-quote">
                    {(numTotal == undefined || numX * numY == numTotal) && <> ({numX} x {numY}) </> }
                    {numTotal != undefined && numX * numY != numTotal && <>  &nbsp; ({numTotal}) </> }
                    &nbsp;  &nbsp;  </span>} </td>
                  </tr>

                  <tr key="presentedOptions">
                    <td align="left">
                      <span className="arrow">
                        <i className="fa fa-arrows-h" aria-hidden="true"></i>
                      </span>
                      <span className="info-quote">
                        {t('layouts.options_layout_dimension_width')}  {presentedOptions[ t('layouts.options_layout_dimension_width') ]}
                      </span>
                    </td>

                    <td align="left">
                      <span className="arrow">
                        <i className="fa fa-arrows-v" aria-hidden="true"></i>
                      </span>
                      <span className="info-quote">
                        {t('layouts.options_layout_dimension_height')}  {presentedOptions[ t('layouts.options_layout_dimension_height') ]}
                      </span>
                    </td>

                    <td align="right">
                    {redrawFinished.current &&
                      <span className="info-quote">
                        <a onClick={setShowDimensionTrue} className="btn btn-default btn-sm no-border simple-btn">
                        <i className="icon-tools"> </i> &nbsp; {t('layouts.show_dimensions') }</a>
                      </span>
                    }
                    </td>
                  </tr>

                  {onlyForPlotter && (
                    <tr key="onlyForPlotter_warning">
                      <td colSpan="3">
                       <div className="alert alert-info fade in">
                        <i className="fa fa-info-circle"></i>&nbsp; <strong> {t('layouts.nesting_is_only_for_plotter')} </strong>
                      </div>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </div>
        </div>


        {showDimensions && showDimensionsContentPopup()}
      </div>
  )
}

export default LayoutSvg;