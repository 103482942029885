import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import { OptionsCommon } from 'components/show_drawing/redraw_form/index.js';
import OptionLenghtWithInFractions from 'components/show_drawing/redraw_form/options/OptionLenghtWithInFractions.jsx';

const OptionLenghtWithUnit = ({ id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested }) => {
  if(unit == 'infr') { return <OptionLenghtWithInFractions
      id={id}
      drawing={drawing}
      option={option}
      unit={unit}
      optionsUserChanged={optionsUserChanged}
      addToOptionsUserChanged={addToOptionsUserChanged}
      setRedrawRequested={setRedrawRequested}
    />}

  const formattedValue = (value) => {
    let toFormat = parseFloat(value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(unit == 'in' ? 4 : 2);

    return formatted;
  }

  const redrawOnChange = false;
  const [isVisible, value, valueAutoCalculated, recommendedValue, onChange, showAbcHelperLine, _changed, highlighted, d_input_add_on_ref] = OptionsCommon( { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange } )

  const formatValueDecimal = (e) => {
    let formattedValueRes = formattedValue(e.target.value)
    onChange( { target: { value: formattedValueRes} } );
  }


  if (!isVisible) { return <input type="hidden" name={option.name} value={value} /> }

  return (
     <div className={`${styles.row}`}>
      <div className={`input-group ${highlighted ? styles.highlighted : ''}`}>
        <span className={`d_input_add_on input-group-addon input-group-addon-${window.locale} ${styles.input_add_on}`} ref={d_input_add_on_ref}>
          {option.presented_name}
        </span>

        <input
          name={option.name}
          type="text"
          value={value}
          onChange={onChange}
          onBlur={formatValueDecimal}
          readOnly={option.readonly}
          autoComplete="off"
          onFocus={showAbcHelperLine}
          className={`${styles.input_text_align} drawing-option-input form-control ${ option.presented_name.length > 20 ? ' drawing-option-input-wide' : '' }`} aria-label=""
        />

        <span className="input-group-addon font13 input-group-addon-small"> {unit} </span>

      </div>

      {valueAutoCalculated && <> <small> { t('drawing.this_has_been_automaticly_calculated') } </small> <br /></> }
      {!valueAutoCalculated && option.value_formula && recommendedValue &&  <> <small> <div> {t('drawing.choose_option_recommmended')}: {recommendedValue} </div> </small> </>}
    </div>)
}

export default OptionLenghtWithUnit;
