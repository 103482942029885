import React, { useState, useEffect }  from 'react';
import { GetRequest } from 'components/common/index.js';
import { SelectFlap } from 'components/show_drawing/redraw_form/index.js';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const TagsAsOptions = ( { id, setId, drawing, theme, debug } ) => {
  const allowedTags = ["postal_lock", "top_postal_lock", "bottom_postal_lock"];
  const key = (tag) => { return tag.flag }
  const [tags, setTags] = useState([]);
  const [values, setValues] = useState( {} );
  const [searchResult, setSearchResult] = useState( id );
  const [availableDrawings, setAvailableDrawings] = useState( [] );
  const setValue = (key, value) => {
    setValues( (prev) => {
      prev[ key ] = value;
      return { ...prev }
    } )
  }

  const sortByName = (a, b) => {
    if (a.name < b.name) { return -1 }
    if (a.name > b.name) { return 1 }
    return 0;
  }

  const fetchTagsAsOptions = () => {
    // https://diecuttemplates.com.dev/tags-as-options/72606
    GetRequest({
      url: `/tags-as-options/${id}`,
      callback: (data) => {
        setTags( () => data.tags.filter( (tag) => { return allowedTags.includes(tag.flag)  } ) )

        for(let i = 0; i< data.tags.length; i++) {
          setValue(data.tags[i].flag, data.tags[i].value)
        }

        setAvailableDrawings( () => data.available_drawings )
      }
    })
  }

  const onChange = (e) => {
    setValue(e.target.name, e.target.checked)
  }

  const onClick = (e, name) => {
    e.preventDefault();
    setValue(name, !values[name])
  }

  const disabled = (key) => {
    var found = false;
    var nextValues = { ...values }
    nextValues[key] = !nextValues[key]

    for(let i = 0; i < availableDrawings.length; i++) {
      var condition = true;

      for (const [key, value] of Object.entries(nextValues)) {
        condition = condition && availableDrawings[i][key] == value
      }
      if (condition) { found = true }
    }

    return !found;
  }

  const inputForX = (tag, i) => {
    var isDisabled = disabled(tag.flag);
    var othersNull = true;
    return (<>
      <div className="input-group mb-3" key={tag.flag}>
        <span onClick={ isDisabled ? () => {} : (e) => { onClick(e, tag.flag) }} className="">
         <span className={`${isDisabled ? styles.disabled_label : ''} font13`}  >
          {t(`models.${tag.flag}`)}
          </span>

          <div className={styles.div_boolean} key={tag.flag}>

          </div>
        </span>
      </div>
    </>)
  }

  const inputFor = (tag, i) => {
    var isDisabled = disabled(tag.flag);
    var othersNull = true;
    return (<div class="input-group mb-3">
      <span class="input-group-text font13"  style={{ display: 'flex', "width": '100%'}}  >{t(`models.${tag.flag}`)}

        <div  style={{ display: 'flex', "width": '100%', "justify-content" : "flex-end"  }}>
          <input
            name={tag.flag}
            key={tag.flag}
            type="checkbox"
            value="1"
            className=""
            checked={values[tag.flag]}
            disabled={isDisabled}
            autoComplete="off"
            onChange={onChange}
          />
        </div>
      </span>
    </div>)
  }

  const onApplyCommand = (msg) => {
  if(msg.detail.id != id) { return }
    let apply_command =  msg.detail.apply_command;
    let parts = apply_command.split('_');
    let last_i = parts.length - 1;
    let apply_command_value  = parts[ last_i ] == "1"
    let apply_command_key = parts.slice(1, last_i ).join('_');
    tags.map( (tag) => {
      if(tag.flag == apply_command_key && tag.value == !apply_command_value) {
        setValue(tag.flag, apply_command_value)
        trackEvent('js_apply_command_applied', { id: id, apply_command: apply_command, key: apply_command_key, value: apply_command_value } );
      }
    })
  }

  // useEffect starts here
  useEffect( () => {
    for(let i = 0; i < availableDrawings.length; i++) {
      var condition = true;

      for (const [key, value] of Object.entries(values)) {
        condition = condition && availableDrawings[i][key] == value
      }

      if(condition) {
        setSearchResult( () => availableDrawings[i].id )
        setId( () => availableDrawings[i].id)
        break;
      }
    }
  }, [values] )

  useEffect(fetchTagsAsOptions, [id])
  // useEffect ends here

  MyEventListener([{
    key: Constants.drawing_apply_command,
    callback: onApplyCommand
  }])

  return (<div key="tags-as-options" className="row mb-6">
      { tags.map(inputFor) }
    </div>
  );
}

export default TagsAsOptions;
