import React, { useState, useEffect }  from 'react';
import { GetRequest } from 'components/common';
import { NumberWithUnitInput } from 'components/common';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';

const MaterialChooser = ({ id, unit, register, errors, drawing }) => {
  const custom = 'custom';
  const [materialId, setMaterialId] = useState(drawing.default_material_id);
  const [customMaterialThickness, setCustomMaterialThickness] = useState( unit == 'in' ? 0.0394 : 1 );
  const [materials, setMaterials] = useState( [] )
  const [changed, setChanged] = useState(false)
  const [askCustomMaterial, setAskCustomMaterial] = useState(false)

  const fetchMaterials = () => {
    // https://diecuttemplates.com.dev/materials/en/69005/mm
    GetRequest({
      url: `/materials/${window.locale}/${id}/${unit}`,
      callback: (data) => {
        setMaterials( (_prev) => data.values);
        setAskCustomMaterial( (_prev) => data.ask_custom_material)
        setMaterialId( () => data.default_material_id || data.values[0].materials[0].id )
      }
    })
  }

  const onChange = (e) => {
    setMaterialId(() => e.target.value);
  }

  const onDrawingOptionValueSetMsg = (msg) => {
    if(msg.detail.id == id && msg.detail.name == 'material_id') {
      emitEvent('addDebugEntry', { event: "[MaterialChooser] material changed", name: msg.detail.name, value: msg.detail.value})
      var value = msg.detail.value;
      var valueParts = value.split('|')
      if(valueParts.length == 2 && valueParts[0] == custom) {
        value = custom
        setCustomMaterialThickness( () => valueParts[1])
      }
      setMaterialId( () => value )
    }
  }

  const handleCustomMaterialChange = (e) => {
    setCustomMaterialThickness((_prev) => e.target.value)
  }

  const publishChangedEvent = () => {
    emitEvent('addDebugEntry', { event: "[OptionsCommon] SetValue event emitted", id: id, name: 'material_id', value: materialId, changed: changed} )
    emitEvent(Constants.drawing_option_change_completed, {id: id, name: 'material_id'})
  }

  // useEffect
  useEffect(fetchMaterials, [id, unit]);
  useEffect( () => {
    setCustomMaterialThickness( () =>  unit == 'in' ? 0.0394 : 1  )
  }, [unit]);

  useEffect( () => {
    if(drawing.default_material_id != undefined) {
      setMaterialId(() => drawing.default_material_id);
    }
  } , [drawing])

    useEffect(publishChangedEvent, [materialId, id, unit, drawing.default_material_id] )
  // useEffect

  MyEventListener([
    {
      key: Constants.drawing_option_set_value,
      callback: onDrawingOptionValueSetMsg
    }
  ])

  return (
    <div className={styles.row}>
        <span> { t('drawing.material') } </span>
        <select name="material_id" value={materialId} onChange={onChange} className={`form-control ${styles.form_control_v2}`}>
          {materials.map( (material) => (
            <optgroup label={material.group_name} key={material.group_name}>
            { material.materials.map((m) => <option value={m.id} key={m.id}>{m.name} </option>) }
          </optgroup> ) ) }

          {askCustomMaterial && (<optgroup key="ask_custom_material">
            <option value={custom}> { t('drawing.enter_custom_material') } </option>
          </optgroup>
          ) }

        </select>

        {materialId == custom && (<div style={{ marginTop: "8px" }}>
          <NumberWithUnitInput
            name="custom_material_value"
            unit={unit}
            placeholder=""
            register={register}
            class_name="bla"
            errors={errors}
            validators={[]}
            value={customMaterialThickness}
            onChange={ handleCustomMaterialChange }
          />

        </div>)}
    </div>
  )
}

export default MaterialChooser;
