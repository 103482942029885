const ListenCondition = {
  listener: ( { config, condition, item, navSection, setValue, GetRequest, setReadOnly, setReadOnlyMessage, setNotAvailable, setNotAvailableReason, requestPublishItemOnChange, beforeFilteredValuesChange, filteredValues, setFilteredValues, d, options, navSectionColumnType, fetchUserUnitPrices, fetchSystemUnitPrices} ) => {
    return (e) => {
      let detail = e.detail;

      const isSameItem = (other) => { return sameNavSection() && item.is_item_extra == other.is_item_extra && item.extra_item_reason == other.extra_item_reason && item.uuid == other.uuid; };

      const sameNavSection = () => {
        if (e?.detail?.nav_section?.index === undefined) { return false; }
        if (navSection?.index === undefined) { return false; }

        return navSection.index == e.detail.nav_section.index;
      }

      const sameItem = () => { return isSameItem(e.detail.item); }
      const v = (key) => {  alert('do not use this'); }


      const nav_lamination_single_side = () => e.detail.values.lamination == 'one_side';
      const nav_lamination_double_side = () => e.detail.values.lamination == 'double_side';

      const nav_printing_none =        () => e.detail.values.printing_side == 'none'
      const nav_printing_single_side = () => e.detail.values.printing_side == 'one_side'
      const nav_printing_double_side = () => e.detail.values.printing_side == 'double_side'

      const nav_printing_block_double_block = () => e.detail.values.printing_block == 'one_block'
      const nav_printing_block_single_block = () => e.detail.values.printing_block == 'double_block'

      if(condition.type == 'set_eval_value_if') {
        if (eval(condition.if)) {
          let value_eval_result = eval(condition.value_eval);
          setValue(config.name, (_prev) => { return value_eval_result} );
          setValue('updated_at', (_prev) => { return Date.now() });
        }
      }

      if(condition.type == 'make_readonly_and_not_available_if') {
        if (eval(condition.if)) {
          if (eval(condition.readonly_condition)) {
            setValue(config.name, (_prev) => { return 'not-available';});
            setValue('updated_at', (_prev) => { return Date.now() } );
            setReadOnly( (_prev) => { return true });
            setReadOnlyMessage( (_prev) => { return t(condition.readonly_message); });
          } else {
            setReadOnly( (_prev) => { return false });
            setReadOnlyMessage( (_prev) => { return  ''});
          }
        }
      }

      if(condition.type == 'select_values_filters_if') {
        if (eval(condition.if)) {
          if(beforeFilteredValuesChange != undefined) { beforeFilteredValuesChange(); }

          for(let i=0; i< condition.select_filters.length; i++) {
            // note that this is filtering config.values
            setFilteredValues( (_prev) => { return config.values.filter( (valuePair) => { return eval(condition.select_filters[i].filter); } ) } );
          }
        }
      }

      if(condition.type == 'refetch_user_unit_prices_and_filter') {
        if (eval(condition.if)) {
          setValue(config.name, (_prev) => { return 'not-available';});
          setValue('updated_at', (_prev) => { return Date.now() } );
          if(condition.filter_eval != undefined) {
            var filter_eval = `${condition.filter_eval}`;
            if(condition.filter_eval_s != undefined) {
              for(let i = 0; i < condition.filter_eval_s.length; i++) {
                filter_eval = filter_eval.replace(`%s${i}`, eval( condition.filter_eval_s[i]))
              }
            }

          } else {
            var filter_eval = `parseInt(user_unit_price.${condition.filter_key}) == ${parseInt(eval(condition.filter_value_eval))}`
          }

          fetchUserUnitPrices(filter_eval);
          fetchSystemUnitPrices(filter_eval);
          setValue('updated_at', (_prev) => { return Date.now() } );
        }
      }

      if(condition.type == 'fetch_changed_user_price_and_make_available_or_unavailable') {
        if(eval(condition.if)) {
          let id = eval(condition.id);

          GetRequest({
            url: `/quote-builder/user_unit_prices/${id}/`,
            callback: (changed_user_unit_price) => {
              if (eval(condition.unavailable_condition)) {
                setValue(config.name, (_prev) => { return 'not-available';});
                setValue('updated_at', (_prev) => { return Date.now() } );
                setNotAvailable( (_prev) => { return true });
                setNotAvailableReason( (_prev) => { return t(condition.unavailable_message); });
              } else {
                setNotAvailable( (_prev) => { return false });
                setNotAvailableReason( (_prev) => { return  ''});
              }
            }
          });
        }
      }

      if(condition.type == 'fetch_changed_user_price_and_make_readonly_or_not') {
        if(eval(condition.if)) {
          let id = eval(condition.id);

          GetRequest({
            url: `/quote-builder/user_unit_prices/${id}/`,
            callback: (changed_user_unit_price) => {
              if (eval(condition.readonly_condition)) {
                setValue(config.name, (_prev) => { return 'not-available';});
                setValue('updated_at', (_prev) => { return Date.now() } );
                setReadOnly( (_prev) => { return true });
                setReadOnlyMessage( (_prev) => { return t(condition.readonly_message); });
              } else {
                setReadOnly( (_prev) => { return false });
                setReadOnlyMessage( (_prev) => { return  ''});
              }
            }
          });
        }
      }

      if(condition.type == 'fetch_changed_user_price_and_set_value') {
        if(eval(condition.if)) {
          let id = eval(condition.id);
            GetRequest({
              url: `/quote-builder/user_unit_prices/${id}/`,
              callback: (changed_user_unit_price) => {
                let value_eval_result = eval(condition.value_eval);
                setValue(config.name, (_prev) => { return value_eval_result; });
                setValue('updated_at', (_prev) => { return Date.now() } );
              }
            });
        }

      }
    }
  }
}

export default ListenCondition;
