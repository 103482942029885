import React, { useState, useEffect }  from 'react';
import styles from 'components/quote_builder_widget/_style.module.scss';
import DrawingOptions from 'components/quote_builder_widget/DrawingOptions';

const QuoteBuilderForm = ( { theme, id, setId, drawing} ) => {

  const theme_default = (
    <div>
      <DrawingOptions
        theme={theme}
        id={id}
        setId={setId}
        drawing={drawing}
      />
    </div>
  )

 if(theme == "default") { return theme_default }

}

export default QuoteBuilderForm;
