import React, { useState, useEffect }  from 'react';

const SelectUnit = ( { id, drawing, unit, theme, setUnit } ) => {

  const onChange = (e) => {
    setUnit( () => e.target.value  )
  }

  const theme_default = (
    <div className="row mb-6">
      <div class="input-group mb-3">
        <span class="input-group-text font13" id="basic-addon3" > { t('my_drawings.options_unit_short') } </span>

        <select class="form-select form-control text-align-right" onChange={onChange} value={unit}>
          <option value="in"> { t('drawing.unit_in') } </option>
          <option value="mm"> { t('drawing.unit_mm') } </option>
        </select>

      </div>
    </div>
  );


  if (theme == "default") { return theme_default }
}

export default SelectUnit;
