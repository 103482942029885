import React, {useEffect, useState, useRef} from 'react';
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const OptionRowFloatInfr = ( {item, description, name, value, unit, abc_line, onChange, onKeyUp, wide, doNotLoadFromLocalStorage, disabled, decreaseNum} ) => {
  const fractionCValues = [2, 4, 5, 8, 10, 16, 25, 32, 50, 64, 100, 128, 256];
  const [valueA, setValueA] = useState(undefined);  // value format = "a+b/c"
  const [valueB, setValueB] = useState(undefined);
  const [valueC, setValueC] = useState(undefined);
  const [defaultValue, setDefaultValue] = useState( value )
  const [_value, _setValue] = useState( defaultValue )
  const valueBRef = useRef();
  const flagToRedraw = useRef();
  const focusBEnabled = useRef();
  const parseValueToInt = (v) => Number.isNaN( parseInt(v) ) ? 0 : parseInt(v);

  const onChangeA = (e) => {
    setValueA( () => parseValueToInt(e.target.value) + "" )
  }

  const onChangeB = (e) => {
    setValueB( () => Math.min(parseValueToInt(e.target.value), valueC) + "" )
  }

  const onChangeC = (e) => {
    setValueC( () => parseValueToInt(e.target.value) )
    focusBEnabled.current = true;
    onKeyUp(e) //this adds to the window.dielineOptions["options_user_changed"] list
  }

  const parseValueStr = (valueStr) => {
    if(valueStr == undefined || valueStr == null) { return }

    const [a, b, c ] = getABCFromStr(valueStr);

    setValueA( () => a )
    setValueB( () => b )
    setValueC( () => c )
  }

  const getABCFromStr = (valueStr) => {
    let a = 0, b, c;
    if (typeof valueStr.includes === 'function') {
      if(!valueStr.includes('+') && !valueStr.includes('/')) {
        return [parseValueToInt(valueStr), 0, 64]
      }

      if (valueStr.includes('+')) {
        const parts = valueStr.split(/[\+\/]/);
        a = parts[0];
        b = parts[1];
        c = parts[2];
      } else {
        const parts = valueStr.split('/');
        b = parts[0];
        c = parts[1];
      }
      return [ parseValueToInt(a), parseValueToInt(b), parseValueToInt(c) ]
    } else {
      return [undefined, undefined, undefined]
    }
  }

  const handleValueWrappedSet = () => {
    _setValue( () => `${valueA}+${valueB}/${valueC}` )
  }

  const fractionCValueOption = (i) => <option value={i}> {i} </option>
  const redrawOn_valueChange = useRef()
  const onRecoveryApplyActionMsg = (msg) => {
    if(msg.detail.id != item.id || msg.detail.klass != item.klass || name != msg.detail.option_name) { return }
    if(msg.detail.option_value != undefined && msg.detail.option_value != '') {
      parseValueStr(msg.detail.option_value);
      redrawOn_valueChange.current = true
    }
  }

  // useEffect
  useEffect(() => {
    if(redrawOn_valueChange.current) {
      $(`#RedrawLayoutButton_${item.uuid}`).trigger('click');
      redrawOn_valueChange.current = false;
    }
  }, [ _value ])
  useEffect(handleValueWrappedSet, [ valueA, valueB, valueC ])
  useEffect(() => { if(valueB == 0 && focusBEnabled.current) { $(valueBRef.current).focus() } }, [ valueB ])
  useEffect(() => {
    if(valueC < valueB) {
      setValueB( () => 0 )
    }
  }, [ valueC ])
  useEffect( () => parseValueStr(value), [ value ])


  const onChangeWrapped = (value) => {
    if(decreaseNum != undefined) { decreaseNum();}
    onChange(value);
  }

  useEffect(() => {
    if(doNotLoadFromLocalStorage) {
      if(decreaseNum != undefined) { decreaseNum();}
      return;
    }

    LoadFromLocalStorage(item, name, onChangeWrapped)
  } ,[]);
  // useEffect

    MyEventListener([
      {
        key: 'recovery_apply_action',
        callback: onRecoveryApplyActionMsg
      }
    ])

  const select = () => {
    return <select value={valueC} onChange={onChangeC} className={`${styles.select_fraction}`} >
      {fractionCValues.map(fractionCValueOption)}
    </select>
  }

  const inputInFr = () => {
    return(
      <table style={{ backgroundColor: '#ffffff' }}>
        <tbody>
          <tr>
            <td>
              <input
                type="number"
                value={valueA}
                onChange={onChangeA}
                autoComplete="off"
                step="1"
                onKeyUp={onKeyUp}
                min="0"
                data-abc-line={abc_line}
                className={`drawing-option-input ${styles.input_text_align} ${styles.fraction_part} form-control form-control-shorter ${wide == "true" ? 'drawing-option-input-wide' : ''} input_use_local_storage ${abc_line != undefined ? "ctl_abc_line": "" } `}
              />
            </td>

            <td>
              <span className={styles.math_sign}> + </span>
            </td>

            <td>
              <input type="number"
                value={valueB}
                onChange={onChangeB}
                autoComplete="off"
                onKeyUp={onKeyUp}
                step="1"
                min="0"
                ref={valueBRef}
                data-abc-line={abc_line}
                className={`drawing-option-input ${styles.input_text_align} ${styles.fraction_part} form-control form-control-shorter ${wide == "true" ? 'drawing-option-input-wide' : ''} input_use_local_storage ${abc_line != undefined ? "ctl_abc_line": "" } `}
              />
            </td>

           <td>
              <span className={styles.math_sign}> / </span>
            </td>

            <td style={{ minWidth: "30px" }}>
              {select()}
            </td>

          </tr>
        </tbody>
      </table>
    )
  }

  return(
    <div className="LayoutRedrawFormInputRow">
      <div className="input-group short-text">
        <span className={`${styles.input_add_on} input-group-addon input-group-addon-en  ${wide == "true" ? 'input-group-addon-wide' : ''}`}>
          {description}
        </span>

        <table className="table" style={{ padding: "0px", margin: "0px"}}>
          <tbody>
            <tr>
              <td className={styles.td_infr_cont}>
                {inputInFr()}
              </td>

              <td style={{ padding: "0px", margin: "0px" }}>  </td>
            </tr>
          </tbody>
        </table>

        <input type="hidden" name={name} value={_value} onChange={() => {}}/>

        <span className="input-group-addon font13 input-group-addon-small"> in </span>
      </div>
    </div>
  );
}

export default OptionRowFloatInfr