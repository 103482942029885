import React, { useState, useEffect }  from 'react';
import FormNew from './FormNew';
import { GetRequest, PostRequest } from 'components/common/index.js';

const List = ( { } ) => {
  const [mode, setMode] = useState('listing')
  const [widgets, setWidgets] = useState([])
  const [identifier, setIdentifier] = useState('')

  const widgetRow = (widget) => {
    return <tr>
      <td> {widget.identifier} </td>
      <td> {widget.domains.join(',')} </td>
      <td> <a href="#" onClick={(e) => showEditForm(e, widget.identifier) }> <i class="fa fa-pencil-square-o" aria-hidden="true"></i> {t('quote_builder_widget.edit')} </a> </td>
      <td> <a href="#" onClick={(e) => deleteConfirm(e, widget.identifier)}> <i class="fa fa-trash" aria-hidden="true"></i> {t('quote_builder_widget.delete')} </a> </td>
    </tr>
  }
  const listofItems = () => {
    return <table className="table table-striped table-bordered-outside quote_builder_table">
      <tbody>
        <tr>
          <th colSpan="4"> {t('quote_builder_widget.my_widgets')} ({widgets.length})  </th>
        </tr>

        <tr>
          <th> {t('quote_builder_widget.identifier')} </th>
          <th> {t('quote_builder_widget.domains')} </th>
          <th colSpan="2"></th>
        </tr>

        { widgets.map(widgetRow) }

      </tbody>
    </table>
  }

  const fetchWidgets = () => {
    if(mode != 'listing') { return }

    GetRequest({
      url: '/quote-builder/user-quote-builder-widgets',
      callback: (data) => {
        setWidgets( (_prev) => data );
      }
    })
  }

  const listofWidgets = () => {
    return (<>
      { widgets.length > 0 && listofItems() }

      <button onClick={ () => setMode( () => 'new' )} className="btn simple-btn">
        <i className="fa fa-plus-square-o" aria-hidden="true"></i>
        <span> &nbsp; {t('quote_builder_widget.new_widget')} </span>
      </button>
      </>)
  }

  const showEditForm = (e, identifier) => {
    e.preventDefault();
    setIdentifier( () => identifier )
    setMode( () => 'edit' )
  }

  const deleteConfirm = (e, identifier) => {
    if(confirm( t('quote_builder_widget.delete_widget_confirm') )) {

    PostRequest({
      url: '/quote-builder/user-quote-builder-widgets-delete',
      data: {
        identifier: identifier,
      },
      callback: ( data ) => {
        setMode( () => 'listing' )
        setIdentifier( () => '' )
        fetchWidgets();
      },
      400: (data) => {
        setErrorMessage( () => data.error )
      }
    })

    }
  }

  // useEffect
  useEffect(fetchWidgets, [mode])
  // useEffect

  return (
    <div style={{ marginLeft: '-20px' }}>
      {mode == 'listing' && listofWidgets()}
      {mode == 'new' && <FormNew setMode={setMode} /> }
      {mode == 'edit' && <FormNew identifier={identifier} setMode={setMode} /> }
    </div>
  )
}

export default List;
