import React, { useState, useEffect }  from 'react';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';

const OptionsCommon = ( { formattedValue, id, drawing, option, unit } ) => {
  const [isVisible, setIsVisible] = useState(option.visible)
  const [defaultValue, setDefaultValue] = useState( formattedValue(option.default_value) )
  const [value, setValue] = useState( defaultValue )
  const [highlighted, setHighlighted] = useState(false)

  const onChange = (e) => {
    if(e.target.type == 'checkbox') {
      setValue( () => e.target.checked ? 1 : 0 )
    } else {
      setValue( () => e.target.value )
    }
    setChanged( () => true );
  }

  // useEffect
  useEffect( () => {
    setDefaultValue( () => formattedValue(option.default_value) )
    setValue( () => formattedValue(option.default_value) )
  } , [option.default_value])
  // useEffect

  return [isVisible, value, onChange, highlighted]
}

export default OptionsCommon;
