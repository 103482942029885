import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import ToolTip from 'components/show_drawing/ToolTip.jsx';
import Constants from 'components/show_drawing/Constants.jsx';
import OptionsCommon from 'components/quote_builder_widget/form/options/OptionsCommon.jsx';

const OptionBoolean = ({ id, drawing, option, unit, setRedrawRequested, optionsUserChanged, addToOptionsUserChanged }) => {
  const formattedValue = (value) => { return value }
  const redrawOnChange = true;
  const [isVisible, value, valueAutoCalculated, recommendedValue, onChange, showAbcHelperLine, changed, highlighted] = OptionsCommon( { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange } )

  // tooltip
  const hasTooltip = () => { return ['cross', 'direction'].includes(option.description_en) }
  const tooltipTitle = () => {
    if(option.description_en == 'cross') { return t('drawing.what_is_cross') }
    if(option.description_en == 'direction') { return t('drawing.what_is_direction') }
    throw new Error("unknown tooltipTitle");
  }

  const tooltipKey = () => {
    if(option.description_en == 'cross') { return 'cross'}
    if(option.description_en == 'direction') { return 'direction'}
    throw new Error("unknown tooltipKey");
  }
  // tooltip

  const onClick = (e) => {
    if(hasTooltip()) { return }
    onChange( {target: { value: value == 1 ? 0 : 1 }} )
  }

  const onClick2 = (e) => {
    if(!hasTooltip()) { return }
    onChange( {target: { value: value == 1 ? 0 : 1 }} )
  }

  if (!isVisible) { return <input type="hidden" name={option.name} value={value} /> }

  return (
    <div className={styles.row}>
      <div className={`${hasTooltip() ? '' : styles.clickable} input-group short-text ${highlighted ? styles.highlighted : ''}`} onClick={onClick}>
        <span className={`input-group-addon input-group-addon-${window.locale} ${styles.input_add_on_for_boolean}`}>

          <span onClick={onClick2} className={`${hasTooltip() ? styles.clickable : ''}`}> {option.presented_name} </span>

          {hasTooltip() && <ToolTip title={tooltipTitle()} toolTipKey={tooltipKey()} />}

          <div className={styles.div_boolean}>
            <input
              name={"__" + option.name}
              type="checkbox"
              value="1"
              onChange={onChange}
              className="drawing-option-input autoredrawonchange"
              checked={value == 1}
              autoComplete="off"
              readOnly={option.readonly_option}
            />
            <input type="hidden" name={option.name} value={value} />
            </div>
        </span>
      </div>

      {valueAutoCalculated && <> <small> { t('drawing.this_has_been_automaticly_calculated') } </small> <br /></> }
      {!valueAutoCalculated && option.value_formula && recommendedValue &&  <> <small> <div> {t('drawing.choose_option_recommmended')}: {recommendedValue} </div> </small> </>}

    </div>
)
}

export default OptionBoolean;
