// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._style-module__select___13wJu{flex:1 1;max-width:calc(100% - 48px);border:1px solid #ccc;height:30px}", "",{"version":3,"sources":["/tmp/build_f18e7502/app/javascript/components/quote_builder_widget/_style.module.scss"],"names":[],"mappings":"AAAA,+BACE,QAAA,CACA,2BAAA,CACA,qBAAA,CACA,WAAA","file":"_style.module.scss","sourcesContent":[".select {\n  flex: 1;\n  max-width: calc(100% - 48px);\n  border: 1px solid #ccc;\n  height: 30px;\n}"]}]);
// Exports
exports.locals = {
	"select": "_style-module__select___13wJu"
};
module.exports = exports;
