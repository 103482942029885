import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';
import { OptionsCommonInfr } from 'components/show_drawing/redraw_form/index.js';

const OptionLenghtWithInFractions =  ({ id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested }) => {
  const fractionCValues = [2, 4, 5, 8, 10, 16, 25, 32, 50, 64, 100, 128, 256];
  const formattedValue = (value) => { return value; }
  const redrawOnChange = false;

  const [isVisible, valueA, valueB, valueC, value, valueAutoCalculated, recommendedValue, onChangeA, onChangeB, onChangeC, showAbcHelperLine, changed, highlighted, d_input_add_on_ref, valueBRef] = OptionsCommonInfr(
    { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange }
  )

  const inputInFr = () => {
    return(
      <table style={{ backgroundColor: '#ffffff' }}>
        <tbody>
          <tr>
            <td>
              <input
                type="number"
                value={valueA}
                onChange={onChangeA}
                readOnly={option.readonly}
                autoComplete="off"
                onFocus={showAbcHelperLine}
                step="1"
                min="0"
                className={`${styles.input_text_align} ${styles.fraction_part} form-control form-control-shorter`} aria-label=""
              />
            </td>

            <td className={option.readonly ? styles.infr_td_disabled : ''}>
              <span className={styles.math_sign}> + </span>
            </td>

            <td>
              <input type="number"
                value={valueB}
                onChange={onChangeB}
                readOnly={option.readonly}
                autoComplete="off"
                onFocus={showAbcHelperLine}
                step="1"
                min="0"
                ref={valueBRef}
                className={`${styles.input_text_align} ${styles.fraction_part} form-control form-control-shorter`} aria-label=""
              />
            </td>

           <td className={option.readonly ? styles.infr_td_disabled : ''}>
              <span className={styles.math_sign}> / </span>
            </td>

            <td className={option.readonly ? styles.infr_td_disabled : ''} style={{ minWidth: "30px" }}>
              {select()}
            </td>

          </tr>
        </tbody>
      </table>
    );}

  const fractionCValueOption = (i) => <option value={i}> {i} </option>

  const select = () => {
    if(option.readonly) { return <span className={styles.disabled_select}> {valueC} </span> }

    return <select value={valueC} onChange={onChangeC} disabled={option.readonly}
      className={`${styles.select_fraction} ${option.readonly ? styles.select_fraction_disabled : ''} `}
      >
      {fractionCValues.map(fractionCValueOption)}
    </select>
  }

  const substringV = 22
  if (!isVisible) { return <input type="hidden" name={option.name} value={value} /> }

  return (
    <div className={`${styles.row}`}>
      <div className={`input-group ${highlighted ? styles.highlighted : ''}`}>
        <span className={`input-group-addon input-group-addon-${window.locale} ${styles.input_add_on}`} ref={d_input_add_on_ref}>
         {option.presented_name.length <= substringV && option.presented_name}
         {option.presented_name.length > substringV && <span title={option.presented_name}> { (option.presented_name).substring(0, substringV-2) }.. </span>}
        </span>

          <table className="table" style={{ padding: "0px", margin: "0px"}}>
            <tbody>
            <tr>
              <td className={styles.td_infr_cont}>
                {inputInFr()}
              </td>

            <td style={{ padding: "0px", margin: "0px" }}>  </td>
            </tr>
            </tbody>
          </table>

          <input type="hidden" name={option.name} value={value} onChange={() => {}}/>

        <span className="input-group-addon font13 input-group-addon-small"> in </span>

      </div>

      {valueAutoCalculated && <> <small> { t('drawing.this_has_been_automaticly_calculated') } </small> <br /></> }
      {!valueAutoCalculated && option.value_formula && recommendedValue &&  <> <small> <div> {t('drawing.choose_option_recommmended')}: {recommendedValue} </div> </small> </>}

    </div>)
}

export default OptionLenghtWithInFractions;
