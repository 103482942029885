import React, { useState, useEffect } from 'react';
import SettingsTabs from 'components/quote_builder/SettingsTabs.jsx';
import SettingsMenu from 'components/quote_builder/SettingsMenu.jsx';

const Settings = ( {config, user_unit_price_key} ) => {
  const [activeItem, setActiveItem] = useState(isMobile() ? undefined : (user_unit_price_key || getCookie('quote_builder_default_settings_item') || "carton_material") );

  useEffect(() => {
    $("html, body").animate({ scrollTop: 100 }, "slow");
  }, [activeItem])

  return (
    <div className="row" style={{"marginLeft": "-30px"}}>
      <div className="col-lg-3 SettingsMenu">
        <SettingsMenu activeItem={activeItem} setActiveItem={setActiveItem} />
      </div>
      <div className="col-lg-9 SettingsMenuMainCont">
        {activeItem == "carton_material" && <SettingsTabs config={config.user_unit_prices.carton_material}
          breadcrumbs="materials,menu_material_cartons" /> }

        {activeItem == "hard_cardboard_material" && <SettingsTabs config={config.user_unit_prices.hard_cardboard_material}
          breadcrumbs="materials,menu_material_hard_cardboards" /> }

        {activeItem == "corrugated_cardboard_material" && <SettingsTabs config={config.user_unit_prices.corrugated_cardboard_material}
          breadcrumbs="materials,menu_material_corrugated_cardboards" /> }

        {activeItem == "pvc_material" && <SettingsTabs config={config.user_unit_prices.pvc_material}
          breadcrumbs="materials,menu_material_pvc" /> }

        {activeItem == "coating_material" && <SettingsTabs config={config.user_unit_prices.coating_material}
          breadcrumbs="materials,menu_material_coating" /> }

        {activeItem == "hot_stamping_material" && <SettingsTabs config={config.user_unit_prices.hot_stamping_material}
          breadcrumbs="menu_hot_stamping,menu_materials"  /> }

        {activeItem == "hot_stamping_printing_block" && <SettingsTabs config={config.user_unit_prices.hot_stamping_printing_block}
          breadcrumbs="menu_hot_stamping,menu_printing_blocks" /> }

        {activeItem == "hot_stamping_machine" && <SettingsTabs config={config.user_unit_prices.hot_stamping_machine}
          breadcrumbs="menu_hot_stamping,menu_machines" /> }

        {activeItem == "cellophane_material" && <SettingsTabs config={config.user_unit_prices.cellophane_material}
          breadcrumbs="menu_cellophane,menu_materials" /> }

        {activeItem == "cellophane_machine" && <SettingsTabs config={config.user_unit_prices.cellophane_machine}
          breadcrumbs="menu_cellophane,menu_machines" /> }

        {activeItem == "printing_machine" && <SettingsTabs config={config.user_unit_prices.printing_machine}
          breadcrumbs="menu_print,printing_machine" /> }

        {activeItem == "guillotine_machine" && <SettingsTabs config={config.user_unit_prices.guillotine_machine}
          breadcrumbs="guillotine_cutting,menu_guillotine_machines" /> }

        {activeItem == "die_cutting_machine" && <SettingsTabs config={config.user_unit_prices.die_cutting_machine}
          breadcrumbs="menu_cutting,menu_die_cutting_machines" /> }

        {activeItem == "metal_strip" && <SettingsTabs config={config.user_unit_prices.metal_strip}
          breadcrumbs="menu_cutting,menu_metal_strips" /> }

        {activeItem == "cutting_board" && <SettingsTabs config={config.user_unit_prices.cutting_board}
          breadcrumbs="menu_cutting,menu_cutting_boards" /> }

        {activeItem == "glueing_machine" && <SettingsTabs config={config.user_unit_prices.glueing_machine}
          breadcrumbs="glueing,glueing_machines" /> }

        {activeItem == "glueing_manual_labor" && <SettingsTabs config={config.user_unit_prices.glueing_manual_labor}
          breadcrumbs="glueing,glueing_manual_labor" /> }

        {activeItem == "glueing_material" && <SettingsTabs config={config.user_unit_prices.glueing_material}
          breadcrumbs="glueing,glueing_materials" /> }

        {activeItem == "lamination_machine" && <SettingsTabs config={config.user_unit_prices.lamination_machine}
          breadcrumbs="lamination,lamination_machines" /> }

        {activeItem == "lamination_manual_labor" && <SettingsTabs config={config.user_unit_prices.lamination_manual_labor}
          breadcrumbs="lamination,lamination_manual_labor"  /> }

        {activeItem == "magnet_material" && <SettingsTabs config={config.user_unit_prices.magnet_material}
          breadcrumbs="menu_magnet,menu_magnets" /> }

        {activeItem == "coating_machine" && <SettingsTabs config={config.user_unit_prices.coating_machine}
          breadcrumbs="menu_coating_labour,coating_machine" /> }

        {activeItem == "coating_manual_labor" && <SettingsTabs config={config.user_unit_prices.coating_manual_labor}
        breadcrumbs="menu_coating_labour,coating_manual_labor"/> }

      </div>
    </div>
  )
}

export default Settings;
