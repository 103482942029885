import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/quote_builder_widget/_style.module.scss';
import QuoteBuilderForm from 'components/quote_builder_widget/QuoteBuilderForm.jsx';
import ThreeDPreview from 'components/quote_builder_widget/ThreeDPreview.jsx';
import { GetRequest } from 'components/common/index.js';

const QuoteBuilderWidget = ( { defaultId } ) => {
  const [id, setId] = useState(defaultId)
  const theme = "default";
  const myRef = useRef();
  const [drawing, setDrawing] = useState({})
  const publishSize = () => {
    if(drawing.catalog_name == undefined) { return }
    return;

    if(window.parent != undefined) {
      const message = {
        type: 'diecuttemplates.iframe_set_size',
        data: { identifier: drawing.catalog_name, width: "400", height: "400" },
      };
      window.parent.postMessage(message, "*")
    }
  }

  const getDrawing = () => {
    // https://diecuttemplates.com.dev/drawing-data/72606
    if(id == null) { return }

    GetRequest({
      url: `/drawing-data/${id}`,
      callback: (data) => {
        setDrawing( () => data.drawing)
      }
    })
  }

  const onDrawingFetched = () => {
    if(drawing.catalog_name == undefined) { return }
    console.log({drawing})
    publishSize();
  }
  // useEffect
  useEffect(getDrawing, [id])
  useEffect(onDrawingFetched, [drawing])
  // useEffect

  const theme_default = (
    <div ref={myRef}>

      <div className="card" style={{margin: "10px"}}>

        <div className="row">
          <div className="col-6">
            <div style={{margin: "20px"}}>
              {drawing.image != undefined && <img src={drawing.image.three_d_image_url} width="200" /> }
            </div>
          </div>
          <div className="col-5">
            <br />
            <center> <h3> Get a Quote </h3> </center> <br />
            <QuoteBuilderForm
              id={id}
              setId={setId}
              drawing={drawing}
              theme={theme}
              />
          </div>
        </div>

      </div>
    </div>
  )

  if(theme == "default") { return theme_default }
}

export default QuoteBuilderWidget;
