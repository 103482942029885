import React, { useState, useRef, createRef, useContext }  from 'react';

const ConfirmPopup = ({myRef}) => {
  const [internal, setInternal] = useState({});
  const modalRef = useRef();
  const onClick = () => {};
  const hide = (e) => { e.preventDefault(); modalRef.current.style.display = "none"; }
  const show = () => { modalRef.current.style.display = "block"; }

  myRef.current = {
    internal: {
      modalRef: modalRef,
      onClick: onClick,
      question: '',
      hide: hide,
      show: show,
    },
    setInternal: setInternal
  }

  return (
    <div className="modal" ref={modalRef} >
      <div className="modal-content">
        <span className="close" onClick={hide} >&times;</span>
        <div className="Question text-left"> <br /> <strong> {internal.question} </strong> <br /></div>
        <hr />
        <div className="text-left">
          <button onClick={hide}> {t('quote_builder.cancel')} </button>
            &nbsp;
            &nbsp;
            &nbsp;
          <button onClick={internal.onClick} className="btn simple-btn"> {t('quote_builder.ok')} </button>
        </div>
      </div>
    </div>
  )
}

const AskConfirm = (myRef, question, onConfirm, args) => {
  myRef.current.internal.show();

  myRef.current.setInternal((previous) => {
    previous['question'] = question;
    previous['onClick'] = (e) => {
      e.preventDefault();
      onConfirm(args, myRef.current.internal.hide(e))
    };
    return { ...previous };
  });

}

export {
  AskConfirm,
  ConfirmPopup,
};

