import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import OptionsCommon from 'components/quote_builder_widget/form/options/OptionsCommon.jsx';

const OptionLenghtWithUnit = ({ id, drawing, option, unit, theme }) => {
  const formattedValue = (value) => {
    let toFormat = parseFloat(value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(unit == 'in' ? 4 : 2);

    return formatted;
  }

  const [isVisible, value, onChange, highlighted] = OptionsCommon( { formattedValue, id, drawing, option, unit } )

  const formatValueDecimal = (e) => {
    let formattedValueRes = formattedValue(e.target.value)
    onChange( { target: { value: formattedValueRes} } );
  }

  const theme_default = (
    <div className="row mb-6">
      <div class="input-group mb-3">
        <span class="input-group-text font13"> { option.presented_name }  </span>
        <input type="text"
          className="form-control text-align-right"
          value={value}
          onChange={onChange}
          onBlur={formatValueDecimal}
          readOnly={option.readonly}
          autoComplete="off"
        />
        <span className="input-group-text font13"> {unit} </span>
      </div>
    </div>)


  if(theme == "default") { return theme_default }
}

export default OptionLenghtWithUnit;
