import React, {useEffect} from 'react';
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'
import OptionRowFloatInfr from 'components/layout/options/OptionRowFloatInfr.jsx'

const OptionRowFloat = ( {item, description, name, value, unit, abc_line, onChange, onKeyUp, wide, doNotLoadFromLocalStorage, disabled, decreaseNum} ) => {
  if(unit == "infr") {
    return <OptionRowFloatInfr
      item={item}
      description={description}
      name={name}
      value={value}
      unit={unit}
      abc_line={abc_line}
      onChange={onChange}
      onKeyUp={onKeyUp}
      wide={wide}
      doNotLoadFromLocalStorage={doNotLoadFromLocalStorage}
      disabled={disabled}
      decreaseNum={decreaseNum}
    />
  }

  const formatValue = (value) => {
    let toFormat = parseFloat(value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(unit == 'in' ? 4 : 2);
    onChange(formatted);
  }

  useEffect(() => formatValue(value), []);

  const onChangeWrapped = (value) => {
    if(decreaseNum != undefined) { decreaseNum();}
    onChange(value);
  }

  useEffect(() => {
    if(doNotLoadFromLocalStorage) {
      if(decreaseNum != undefined) { decreaseNum();}
      return;
    }

    LoadFromLocalStorage(item, name, onChangeWrapped)
  } ,[]);

  return(
    <div className="LayoutRedrawFormInputRow">
      <div className="input-group short-text">
        <span className={`input-group-addon  ${wide == "true" ? 'input-group-addon-wide' : ''}`}>
          {description}
        </span>
        <input
          onChange={ e => onChange(e.target.value)}
          onBlur={e => formatValue(e.target.value)}
          onKeyUp={e => { if(onKeyUp != undefined) { onKeyUp(e.target.value)} }}
          data-abc-line={abc_line}
          autoComplete="off"
          type="text"
          disabled={disabled}
          className={`drawing-option-input form-control ${wide == "true" ? 'drawing-option-input-wide' : ''} input_use_local_storage ${abc_line != undefined ? "ctl_abc_line": "" } `}
          name={name}
          value={value}
        />
        <span className="input-group-addon"> {unit} </span>
      </div>
    </div>
  );
}

export default OptionRowFloat