import React, { useState, useEffect }  from 'react';

const ThreeDPreview = ( { } ) => {

  return (
    <div>
      ThreeDPreview
    </div>
  )
}

export default ThreeDPreview;
