import React, { useState, useEffect }  from 'react';

const SelectQuantity = ( { id, drawing, unit, theme } ) => {

  const [value, setValue] = useState(1000)
  const theme_default = (
    <div className="row mb-6">
      <div class="input-group mb-3">
        <span class="input-group-text font13"> Quantity </span>
         <input type="number" class="form-control text-align-right" value={value} onChange={ (e) => setValue( () => e.target.value )  } />
      </div>
    </div>
  );

  if (theme == "default") { return theme_default }
}

export default SelectQuantity;
