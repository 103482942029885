import React, { useEffect, useState, useRef } from 'react';

import { PostRequest } from 'components/common/index.js';

import SignUp from 'components/session/SignUp.jsx';
import SignIn from 'components/session/SignIn.jsx';

import { ContentPopup } from 'components/ContentPopup.jsx';

const LogInRequired = ({ mode, setMode, promptForCreateAccountEmail, callback }) => {
  const handleBackClick = (e) => {
    e.preventDefault();
    setShowSignUp(() => false)
    setShowSignIn(() => true)
  }

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right'}}>
        &nbsp;
        &nbsp;
        &nbsp;
        <button className="btn simple-btn"  onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }

  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);

  const [backButtonVisible, setBackButtonVisible] = useState(true);

  const handleShowSignUp = (e) => {
    e.preventDefault();
    setShowSignUp(() => true)
    setShowSignIn(() => false)
  }

  const handleShowSignIn = (e) => {
    e.preventDefault()
    setShowSignIn(() => true)
    setShowSignUp(() => false)
  }

  useEffect(() => {
    if(promptForCreateAccountEmail != '' && promptForCreateAccountEmail != undefined) {
      setShowSignIn(() => true)
      setShowSignUp(() => false)
      setShowSignInPopUp( () => true )
    }
  }, [promptForCreateAccountEmail])

  const [showSignInPopUp, setShowSignInPopUp] = useState(false)

  const showSignInContentPopupRef = useRef();
  const showSignInContentPopup = () => {
    let beforeClose = () => {
      showSignInContentPopupRef.current.style.display = "none";
      setShowSignInPopUp(false);
    };

    let onSuccess = () => {
      beforeClose();
      callback();
    }

    let content = (
      <div className="testimonials-block preview-promo-block">
       {showSignUp && backButtonVisible && (
        <>
          {backButton()}
        </>
      )}
        {showSignUp && <SignUp setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} setBackButtonVisible={setBackButtonVisible} onSuccess={onSuccess} />}
        {showSignIn && <SignIn promptForCreateAccountEmail={promptForCreateAccountEmail} setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} onSuccess={onSuccess}/>}
     </div>)

    return <ContentPopup contextClass="wrapper" myRef={showSignInContentPopupRef} autoOpen={true} content={content} cancelButton={false} beforeClose={beforeClose} notWide={true} />;
  }

  return(<>
    <div className="downloadButtonscontainer">

      <div className="inner">
        <div className="child">
          <button className={`btn btn-default btn-lg download-btn log-in-btn-with-icons-${window.I18nLocale}`} onClick={() => setShowSignInPopUp( () => true )}>
            <img src={window.dxf_svg_and_pdf_icon} className="downloadIcons" />{t('models.log_in_to_continue')}
          </button>
        </div>
      </div>
  </div>

    {showSignInPopUp && showSignInContentPopup()}

    <br />
  </>)
}

export default LogInRequired;
